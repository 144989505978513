import React from 'react';
import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';


const Newsletter = () => (
  <div className='app__newsletter'>
    <div className="newsletter-heading">
      <SubHeading title="Newsletter" />
      <h1 className="headtext__cormorant">Subscribe To Our Newsletter</h1>
      <p className="p__opensans">And never miss latest Updates!</p>
    </div>
    <div className="newsletter-input flex__center">
      <input type="email" placeholder="Enter your email address" />
      <button type="button" className="custom__btn">Subscribe</button>
    </div>
  </div>
);

export default Newsletter;
